/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import SVGFavicon from "../images/favicon.svg"
import OGImage from "../images/og-image.png"

function SEO({ description, lang, meta, title, bodyClass }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: bodyClass,
      }}
      titleTemplate={`${title || site.siteMetadata.title} | Phi`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: "en_US",
        },
        {
          property: `og:title`,
          content: `${title || site.siteMetadata.title} | Phi`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `og:site_name`,
          content: `Phi`,
        },
        {
          property: `og:image`,
          content: OGImage,
        },
        {
          property: `og:image:secure_url`,
          content: OGImage,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `627`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: `${title || site.siteMetadata.title} | Phi`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: OGImage,
        },
      ]}
      link={[
        { rel: "icon", type: "image/svg+xml", href: SVGFavicon },
        { rel: "mask-icon", href: SVGFavicon, color: "#ffc229" },
      ]}

    // meta={[
    //   {
    //     name: `description`,
    //     content: metaDescription,
    //   },
    //   {
    //     property: `og:title`,
    //     content: title,
    //   },
    //   {
    //     property: `og:description`,
    //     content: metaDescription,
    //   },
    //   {
    //     property: `og:type`,
    //     content: `website`,
    //   },
    //   {
    //     name: `twitter:card`,
    //     content: `summary`,
    //   },
    //   {
    //     name: `twitter:creator`,
    //     content: site.siteMetadata.author,
    //   },
    //   {
    //     name: `twitter:title`,
    //     content: title,
    //   },
    //   {
    //     name: `twitter:description`,
    //     content: metaDescription,
    //   },
    // ].concat(meta)}
    >
      <title>{title}</title>
      <script type="application/ld+json">
        {
          `
            {
              "@context": "http://schema.org",
              "@type": "Corporation",
              "logo": "${site.siteMetadata.siteUrl}/phi-logo.png",
              "legalName": "Phenometry P.C.",
              "description": "Surfaces and Items 3D Modeling, Design on Cloud",
              "url": "${site.siteMetadata.siteUrl}",
              "email": "info@phi3d.com",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Athens",
                "addressCountry": "GR",
                "postalCode": "10557",
                "streetAddress": "Apollonos Str. 6"
              },
              "sameAs": [
                "https://www.linkedin.com/company/phenometry",
                "https://www.facebook.com/Phenometry-106626044274936",
                "https://www.instagram.com/phenometry",
                "https://twitter.com/Phenometry"
              ]
            }
          `
        }
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  bodyClass: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
