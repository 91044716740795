import React from "react";
import { H2, H3, Smaller } from "../../styles";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import bullet from "../../images/bullet.svg";
import styled, { useTheme } from "styled-components";
import Container from "../../components/Container";
import Vimeo from "../../components/knowledge/Vimeo";
import Img from "gatsby-image";
import renderInternalLink from "../../utils/renderInternalLink";

const StyledH3 = styled(H3)`
  color: ${(props) => props.theme.colors.brand};
  margin-bottom: 2.4rem;

  :not(:first-child) {
    margin-top: 5rem;
  }
`;

const UL = styled.ul`
  list-style-image: url(${bullet});
  max-width: 60rem;
  padding: 0 2rem;

  & > li {
    padding-left: 1.2rem;
    margin-bottom: 1.6rem;
    font-size: 1.8rem;
    line-height: 2.8rem;

    p {
      margin: 0;
      margin-bottom: 0.4rem;
    }

    > ul {
      list-style: none;
      padding: 0;
      margin: 0;

      > li {
        padding: 0;
        color: ${(props) => props.theme.colors.greyLight};
      }
    }
  }
`;

const ArticleImage = styled(Img)`
  border-radius: 0.4rem;

  img {
    width: 100%;
    height: auto;
  }
`;

const ArticleContainer = styled(Container)`
  padding-top: 8rem;
  padding-bottom: 8rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: clamp(3rem, ((120 / 1328) * 100vw), 12rem);
  box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.colors.offBlack};
`;

const ArticleHeader = styled.header`
  margin-bottom: 2.4rem;
`;

const ArticleSubtitle = styled(Smaller)`
  display: inline-block;
  margin: 0 0 1.2rem 0;
  color: ${({ theme }) => theme.colors.grey};
`;

const ArticleDescription = styled.div`
  margin-top: clamp(2rem, ((50 / 768) * 100vw), 5rem);
  max-width: 50rem;
  color: ${(props) => props.theme.colors.offWhite};

  p {
    margin-bottom: 4rem;
    font-weight: 300;
    font-size: 2.4rem;
    line-height: 3.4rem;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const ArticleVideo = styled.div`
  .plyr {
    padding: 0;
    margin: 6rem 0;
    border-radius: 0.4rem;
  }
`;

const options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => <StyledH3>{children}</StyledH3>,
    [BLOCKS.UL_LIST]: (node, children) => <UL>{children}</UL>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => <ArticleImage {...node.data.target} />,
    [INLINES.ENTRY_HYPERLINK]: renderInternalLink,
  },
};

const nice_id = (update) => update.replace('Version ', 'v');

export default function Update({ update }) {
  const theme = useTheme();

  return (
    <ArticleContainer as="article" id={nice_id(update.title)}>
      <section>
        <ArticleHeader>
          <H2 color={theme.colors.brand}>{update.title}</H2>
          <ArticleSubtitle>{update.date}</ArticleSubtitle>
        </ArticleHeader>
        {update.description && <ArticleDescription
          dangerouslySetInnerHTML={{
            __html: update.description.childrenMarkdownRemark[0].html,
          }}
        />}
        {update.video && (
          <ArticleVideo>
            <Vimeo src={update.video.url} />
          </ArticleVideo>
        )}
      </section>

      <section>{update.body && renderRichText(update.body, options)}</section>
    </ArticleContainer>
  );
}
