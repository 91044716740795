import './index.scss';
import React, { useEffect } from "react"
import Button from "../../components/Button"
import Layout from "../../layouts/default"
import { H1, H2 } from "../../styles"
import Update from "./update"
import Page from "../../components/Page"
import usePageHeader from "../../hooks/usePageHeader"
import styled from "styled-components"
import { gap } from "../../styles/functions"
import SEO from '../../components/seo';

const TitleCTAWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  ${gap("2rem")};
`

export function UpdatesPage({ data }) {
  const updates = data.allContentfulPatchNote.nodes
  const [pageHeaderRef, pageHeaderHeight] = usePageHeader()

  useEffect(() => {
    if (location.hash.length <= 1) return
    const elem = document.getElementById(location.hash.slice(1))
    if (!elem) return
    setTimeout(() => {
        elem.scrollIntoView()
    }, 500)
  })

  return (
    <Layout>
      <SEO title='Updates' />
      <Page className='updates-page' as="main" pageHeaderHeight={pageHeaderHeight}>
        <Page.Header as="header" columnWidth={1280} ref={pageHeaderRef}>
          <Page.Subtitle>We constantly deliver new features</Page.Subtitle>
          <TitleCTAWrapper>
            <H1 as={H2}>Phi Updates</H1>
            <Button
              variant="ghost"
              to="https://phi.nolt.io/"
              href="https://phi.nolt.io/"
              target="_blank"
              rel="noopener noreferrer"
              title='Suggest a feature on nolt.io'
            >
              Any cool ideas?{" "}
              <span
                css={`
                  color: ${(props) => props.theme.colors.brand};
                `}
              >
                Suggest a feature
              </span>
            </Button>
          </TitleCTAWrapper>
        </Page.Header>
        {updates.map((update) => (
          <Update key={update.contentful_id} update={update} />
        ))}
      </Page>
    </Layout>
  )
}
