import { useEffect, useRef, useState } from "react"

export default function usePageHeader(delay = 150) {
  const pageHeaderRef = useRef(null)
  const [pageHeaderHeight, setPageHeaderHeight] = useState(0)

  useEffect(() => {
    const header = pageHeaderRef.current
    setPageHeaderHeight(header.getBoundingClientRect().height)

    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(
        () => setPageHeaderHeight(header.getBoundingClientRect().height),
        delay
      )
    }

    window.addEventListener("resize", resizeListener)

    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return [pageHeaderRef, pageHeaderHeight]
}
