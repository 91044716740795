import { graphql } from "gatsby"
import { UpdatesPage } from '../features/updates'

export default UpdatesPage

export const pageQuery = graphql`
  query updatesPage {
    allContentfulPatchNote(
      filter: { slug: { ne: "demo" } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        date(formatString: "dddd, D MMMM YYYY")
        description {
          description
          childrenMarkdownRemark {
            html
          }
        }
        title
        video {
          contentful_id
          url
          name
        }
        contentful_id
        body {
          raw
          references {
            ... on ContentfulAsset {
              title
              contentful_id
              __typename
              fluid(maxWidth: 2560) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            ... on ContentfulPost {
              contentful_id
              slug
              title
              __typename
            }
          }
        }
      }
    }
  }
`
