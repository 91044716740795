import styled from "styled-components"
import { Smaller } from "../styles"
import Container from "./Container"

const Page = styled.main`
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ pageHeaderHeight }) => pageHeaderHeight}px;
    background-color: ${({ theme }) => theme.colors.offBlack};
    z-index: -1;
  }
`

const PageHeader = styled(Container)`
  padding-top: calc(var(--header-height) + 8.6rem);
  padding-bottom: 4rem;
`

const PageSubtitle = styled(Smaller)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.grey};
  margin: 0 0 1.2rem;
`

Page.Header = PageHeader
Page.Subtitle = PageSubtitle

export default Page
