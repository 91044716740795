import { Link } from "gatsby"
import { kebabCase } from "lodash"
import React from "react"

export default function renderInternalLink(node, children) {
  if (node.data.target.__typename === "ContentfulPost") {
    return (
      <Link
        id={kebabCase(node.data.target.title)}
        to={`/learn/${node.data.target.slug}`}
      >
        {node.data.target.title}
      </Link>
    )
  } else if (node.data.target.__typename === "ContentfulTool") {
    return (
      <Link
        id={kebabCase(node.data.target.title)}
        to={`/tools/${node.data.target.slug}`}
      >
        {node.data.target.title}
      </Link>
    )
  } else {
    return (
      <Link
        id={kebabCase(node.data.target.title)}
        to={`/${node.data.target.slug}`}
      >
        {node.data.target.title}
      </Link>
    )
  }
}
